import {
  type ModelsMessageTemplateEmail,
  type ModelsMessageTemplateSlack,
} from '@lp-lib/api-service-client/public';

export class MessageTemplateUtils {
  static TranslateText(
    text: Nullable<string>,
    vars?: Record<string, string>
  ): string {
    if (!text) return '';
    if (!vars) return text;

    for (const [k, v] of Object.entries(vars)) {
      const regex = new RegExp(`%${k}%`, 'g');
      text = text.replace(regex, v);
    }

    return text;
  }

  static TranslateTemplateEmail(
    template: ModelsMessageTemplateEmail,
    vars?: Record<string, string>
  ): ModelsMessageTemplateEmail {
    return {
      ...template,
      subject: MessageTemplateUtils.TranslateText(template.subject, vars),
      body: MessageTemplateUtils.TranslateText(template.body, vars),
      buttons: template.buttons?.map((button) => ({
        ...button,
        text: MessageTemplateUtils.TranslateText(button.text, vars),
        href: MessageTemplateUtils.TranslateText(button.href, vars),
      })),
    };
  }

  static TranslateTemplateSlack(
    template: ModelsMessageTemplateSlack,
    vars?: Record<string, string>
  ): ModelsMessageTemplateSlack {
    return {
      ...template,
      body: MessageTemplateUtils.TranslateText(template.body, vars),
      buttons: template.buttons?.map((button) => ({
        ...button,
        text: MessageTemplateUtils.TranslateText(button.text, vars),
        value: MessageTemplateUtils.TranslateText(button.value, vars),
      })),
    };
  }
}
