import { Body, Head, Html, Tailwind, render } from '@react-email/components';

export function EmailLayout(props: { children: React.ReactNode }) {
  return (
    <Html>
      <Tailwind>
        <Head />
        <Body
          style={{
            background: 'white',
            color: 'black',
            fontFamily: 'Arial',
          }}
        >
          {props.children}
        </Body>
      </Tailwind>
    </Html>
  );
}

export function renderEmail(component: React.ReactElement) {
  return {
    html: render(<EmailLayout>{component}</EmailLayout>, { pretty: true }),
    text: render(<EmailLayout>{component}</EmailLayout>, { plainText: true }),
  };
}
