import { Button } from '@react-email/components';

export enum EmailButtonStyle {
  Primary = 'primary',
  Danger = 'danger',
  Default = 'default',
}

function getButtonStyle(style: EmailButtonStyle) {
  switch (style) {
    case EmailButtonStyle.Primary:
      return {
        backgroundColor: '#01acc4',
      };
    case EmailButtonStyle.Danger:
      return {
        backgroundColor: '#FF0935',
      };
    case EmailButtonStyle.Default:
      return {
        backgroundColor: '#232325',
      };
    default:
      return {};
  }
}

export interface EmailButtonProps {
  style: EmailButtonStyle;
  text: string;
  href: string;
}

export function EmailButton(props: EmailButtonProps) {
  return (
    <Button
      href={props.href}
      style={{
        fontSize: '16px',
        fontFamily: 'Arial',
        fontWeight: 500,
        color: '#fff',
        borderRadius: '12px',
        textAlign: 'center',
        letterSpacing: '1.25px',
        minWidth: '240px',
        padding: '12px 20px',
        margin: '16px auto',
        ...getButtonStyle(props.style),
      }}
      target="_blank"
    >
      {props.text}
    </Button>
  );
}
