import { useMemo, useState } from 'react';
import {
  useController,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { type ModelsMessageTemplateSlack } from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { apiService } from '../../../services/api-service';
import { useTriggerSendTestModal } from '../MessageSendTest';
import {
  MessageTemplateButtonsEditor,
  MessageTemplateButtonsPreview,
} from '../MessageTemplateButton';
import {
  MessageTemplateMarkdownInput,
  MessageTemplateMarkdownRender,
  MessageTemplateMarkdownToSlackBlocksRender,
} from './MessageTemplateMarkdown';
import { type MessageTemplateFormData } from './Shared';
import { MessageTemplateUtils } from './utils';

function SendTestButton(props: {
  template: ModelsMessageTemplateSlack | null;
}) {
  const { template } = props;

  const triggerSendTestModal = useTriggerSendTestModal();
  const { trigger } = useFormContext<MessageTemplateFormData>();

  const handleSendTest = async () => {
    const result = await trigger('template.slack');
    if (!result) return;

    if (!template) return;

    triggerSendTestModal({
      filterOrganizers: (organizers) => {
        return organizers.filter((organizer) => !!organizer.exUserId);
      },
      onConfirm: async (recipient) => {
        await apiService.message.sendMarkdownSlackMessage({
          recipientId: recipient.uid,
          template,
        });
      },
    });
  };

  return (
    <button
      type='button'
      className='btn text-sms text-secondary'
      onClick={handleSendTest}
    >
      Send Test
    </button>
  );
}

function MessageTemplateSlackPreview(
  props: MessageTemplateEditorSlackFieldsProps & {
    template: ModelsMessageTemplateSlack | null;
  }
) {
  const { template, vars } = props;

  const [showBlocks, setShowBlocks] = useState(false);

  return (
    <div className='w-full'>
      <div className='w-full flex items-center justify-between mb-2'>
        <div className='flex items-center gap-4'>
          <p className='font-bold'>Preview</p>
          <label className='flex items-center gap-2'>
            <input
              type={'checkbox'}
              className='checkbox-dark'
              checked={showBlocks}
              onChange={(event) => setShowBlocks(event.target.checked)}
            />
            <p className='text-sms'>Show Blocks</p>
          </label>
        </div>
        <SendTestButton template={template} />
      </div>
      <div className='w-full min-h-72 p-4 pb-8 border border-secondary rounded-xl'>
        <div className='flex flex-row items-center gap-2'>
          <img
            src={config.slack.botIconURL}
            alt='luna park'
            className='w-6 h-6 border border-[#2E2E2E]'
          />
          <h3 className='font-bold text-sms'>{config.slack.botUsername}</h3>
        </div>
        <div className='mt-1 w-full pl-8 text-sms text-left flex flex-col gap-5'>
          {showBlocks ? (
            <MessageTemplateMarkdownToSlackBlocksRender text={template?.body} />
          ) : (
            <MessageTemplateMarkdownRender text={template?.body} />
          )}

          <MessageTemplateButtonsPreview
            buttons={template?.buttons || []}
            vars={vars || {}}
          />
        </div>
      </div>
    </div>
  );
}

function MessageTemplateSlackBodyField(
  props: MessageTemplateEditorSlackFieldsProps
) {
  const {
    field: { value: body, onChange },
    fieldState,
  } = useController<MessageTemplateFormData, 'template.slack.body'>({
    name: 'template.slack.body',
    defaultValue: '',
    rules: {
      required: true,
    },
  });

  return (
    <MessageTemplateMarkdownInput
      value={body}
      onChange={onChange}
      vars={props.vars}
      error={fieldState.error}
    />
  );
}

function MessageTemplateSlackButtonsField() {
  const {
    fields: buttons,
    append,
    remove,
    move,
    update,
  } = useFieldArray<MessageTemplateFormData, 'template.slack.buttons', 'key'>({
    name: 'template.slack.buttons',
    keyName: 'key',
  });

  return (
    <MessageTemplateButtonsEditor
      buttons={buttons}
      onAdd={append}
      onDelete={(index) => remove(index)}
      onChange={(index, updates) =>
        update(index, {
          ...buttons[index],
          ...updates,
        })
      }
      onMove={move}
    />
  );
}

export interface MessageTemplateEditorSlackFieldsProps {
  vars?: Record<string, string>;
}

export function MessageTemplateEditorSlackFields(
  props: MessageTemplateEditorSlackFieldsProps
) {
  const template = useWatch<MessageTemplateFormData, 'template.slack'>({
    name: 'template.slack',
  });

  const translatedTemplate = useMemo(() => {
    if (!template) return null;
    return MessageTemplateUtils.TranslateTemplateSlack(template, props.vars);
  }, [template, props.vars]);

  return (
    <div className='w-full flex gap-4'>
      <div className='w-1/2 flex flex-col gap-4'>
        <MessageTemplateSlackBodyField {...props} />
        <MessageTemplateSlackButtonsField />
      </div>
      <div className='w-1/2'>
        <MessageTemplateSlackPreview {...props} template={translatedTemplate} />
      </div>
    </div>
  );
}
