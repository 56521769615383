import { Controller, useForm } from 'react-hook-form';

import config from '../../config';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { OrganizerPicker } from '../Organization/OrganizerPicker';

export type SendTestConfirmFunc = (recipient: Organizer) => Promise<void>;

export interface SendTestFormData {
  recipient: Organizer | null;
}

export function SendTestForm(props: {
  filterOrganizers?: (organizers: Organizer[]) => Organizer[];
  onClose: () => void;
  onConfirm: SendTestConfirmFunc;
}) {
  const { filterOrganizers, onClose, onConfirm } = props;
  const { control, handleSubmit, formState } = useForm<SendTestFormData>({
    defaultValues: {
      recipient: null,
    },
  });

  const {
    call: submit,
    state: { error },
  } = useLiveAsyncCall(async (data: SendTestFormData) => {
    if (!data.recipient) {
      throw new Error('Recipient is required');
    }
    await onConfirm(data.recipient);
  });

  return (
    <form
      className='w-full px-5 py-7.5 text-white'
      onSubmit={handleSubmit(submit)}
    >
      <header className='text-2xl text-center font-medium'>
        Send Test Message
      </header>

      <main className='mt-10 flex flex-col gap-7.5'>
        <div>
          <h3 className='text-base font-bold mb-1'>Send to</h3>

          <Controller
            control={control}
            name='recipient'
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <OrganizerPicker
                orgId={config.misc.lunaParkOrgId}
                onChange={onChange}
                defaultOrganizer={value || undefined}
                placeholder='Select a member in Luna Park organization'
                className='h-15'
                filterOrganizers={filterOrganizers}
              />
            )}
          />
        </div>
      </main>

      <footer className='mt-10 relative'>
        <div className='w-full mb-1 text-center text-red-500 text-sm'>
          {err2s(error)}
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            onClick={onClose}
            className='btn-secondary w-40 h-10'
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn-primary w-40 h-10 flex justify-center items-center'
            disabled={formState.isSubmitting}
          >
            {formState.isSubmitting ? 'Sending...' : 'Send'}
          </button>
        </div>
      </footer>
    </form>
  );
}

export function useTriggerSendTestModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    (props: {
      filterOrganizers?: (organizers: Organizer[]) => Organizer[];
      onConfirm: SendTestConfirmFunc;
    }) => {
      triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-160'
            borderStyle='white'
            onClose={p.internalOnCancel}
          >
            <SendTestForm
              filterOrganizers={props.filterOrganizers}
              onConfirm={async (recipient) => {
                await props.onConfirm(recipient);
                p.internalOnConfirm();
              }}
              onClose={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    }
  );
}
