import { Hr, Link, Markdown, Section, Text } from '@react-email/components';
import { EmailButton, EmailButtonProps } from './components';

function Footer(props: EmailTemplateMarkdownProps) {
  // note: the hr is overflowing and causing a scrollbar, hence the overflow: hidden
  return (
    <Section
      style={{
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Hr
        style={{
          border: '1px solid #E5E5E5',
          maxWidth: '100%',
        }}
      />

      <Text
        style={{
          fontSize: '11px',
          lineHeight: '16px',
          textAlign: 'left',
          marginTop: '20px',
          marginBottom: 0,
        }}
      >
        Luna Park helps boost team culture with Events, Icebreakers, DEI
        Celebrations and more
      </Text>
      <Text
        style={{
          color: '#8B9294',
          fontSize: '11px',
          lineHeight: '16px',
          textAlign: 'left',
          margin: 0,
        }}
      >
        All rights reserved.
      </Text>

      <Text
        style={{
          fontSize: '13px',
          lineHeight: '16px',
          textAlign: 'left',
          marginTop: '16px',
          marginBottom: 0,
        }}
      >
        <a href='https://lunapark.com' target='_blank' rel='noreferrer'>
          LunaPark.com
        </a>
      </Text>
      {props.notificationSettingsLink && (
        <Link href={props.notificationSettingsLink} target='_black'>
          <Text
            style={{
              fontSize: '11px',
              lineHeight: '16px',
              color: '#AAAAAA',
              textAlign: 'left',
              margin: 0,
            }}
          >
            Unsubscribe
          </Text>
        </Link>
      )}
    </Section>
  );
}

export interface EmailTemplateMarkdownProps {
  body: string;
  notificationSettingsLink?: string | null;
  buttons?: EmailButtonProps[];
}

export function EmailTemplateMarkdown(props: EmailTemplateMarkdownProps) {
  return (
    <div style={{ width: '100%' }}>
      <Markdown
        markdownCustomStyles={{
          image: {
            maxWidth: '460px',
            height: 'auto',
          },
          p: {
            fontSize: '14px',
            lineHeight: '24px',
            margin: '14px 0',
          },
        }}
        markdownContainerStyles={{
          fontFamily: 'Arial',
        }}
      >
        {props.body}
      </Markdown>

      {!!props.buttons?.length && (
        <Section>
          {props.buttons.map((button, index) => (
            <EmailButton key={index} {...button} />
          ))}
        </Section>
      )}

      <Footer {...props} />
    </div>
  );
}
