import { useController, useWatch } from 'react-hook-form';
import Select from 'react-select';

import { EnumsMessageDistributionChannel } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';
import { MessageTemplateEditorEmailFields } from './MessageTemplateEditorEmailFields';
import { MessageTemplateEditorSlackFields } from './MessageTemplateEditorSlackFields';
import { type MessageTemplateFormData } from './Shared';

function DistributionChannelField(props: { disabled?: boolean }) {
  const options = useInstance(() => [
    {
      label: 'Email',
      value: EnumsMessageDistributionChannel.MessageDistributionChannelEmail,
    },
    {
      label: 'Slack',
      value: EnumsMessageDistributionChannel.MessageDistributionChannelSlack,
    },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsMessageDistributionChannel>>({
      override: { control: { height: 48 } },
    })
  );

  const {
    field: { value, onChange },
  } = useController<MessageTemplateFormData, 'template.type'>({
    name: 'template.type',
  });

  return (
    <div>
      <div className='mb-2 font-bold'>Distribution Channel</div>
      <Select<Option<EnumsMessageDistributionChannel>>
        options={options}
        value={options.find((o) => o.value === value)}
        classNamePrefix='select-box-v2'
        className='w-80'
        styles={styles}
        placeholder='Choose a distribution channel...'
        onChange={(option) => {
          if (!option) return;
          onChange(option?.value);
        }}
        isDisabled={props.disabled}
      />
    </div>
  );
}

export function MessageTemplateFields(props: {
  vars?: Record<string, string>;
  distributionChannelDisabled?: boolean;
}) {
  const distributionChannel = useWatch<
    MessageTemplateFormData,
    'template.type'
  >({
    name: 'template.type',
  });

  return (
    <div className='w-full text-white'>
      <header className='text-2xl font-medium'>Message Template</header>

      <main className='mt-5 w-full flex flex-col gap-4'>
        <DistributionChannelField
          disabled={props.distributionChannelDisabled}
        />

        {distributionChannel ===
          EnumsMessageDistributionChannel.MessageDistributionChannelEmail && (
          <MessageTemplateEditorEmailFields {...props} />
        )}

        {distributionChannel ===
          EnumsMessageDistributionChannel.MessageDistributionChannelSlack && (
          <MessageTemplateEditorSlackFields {...props} />
        )}
      </main>
    </div>
  );
}
